import server from "./axios.config.js";

export const getProvince = function () {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `commonservice/province/getList`
  });
};
export const getCity = function (provinceCode) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `commonservice/city/getList`,
    params: {
      provinceCode: provinceCode
    }
  });
}
export const getCountry = function (cityCode) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `commonservice/county/getList`,
    params: {
      cityCode: cityCode
    }
  });
}
