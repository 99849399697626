export const handleAreaData = (data) => {
  const result = []
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const text = element.name
    const value = element.code
    if (element.cityCode) { // 是否是第三层 区
      result.push({
        text,
        value
      })
    } else {
      result.push({
        text,
        value,
        children: []
      })
    }
    // }
  }
  return result
}
